import React from "react";
import Ultimos from "../../Ultimos";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./index.css";
import { useCons } from "../../context/ConsContext";

const BodyModel = ({ model }) => {
  const { buttonData } = useCons();

  return (
    <div className="section">
      <div className="container-lg flex flexCol">
        <h1 className="textCenter relative">
          Volkswagen <span>{model.nombre}</span>
        </h1>
        <Ultimos cupos={model.cupo} />
        <div
          className="galeriaText flex flexRow flexWrap space"
          style={{
            backgroundColor: model.color,
          }}
        >
          <div className="galeria">
            <Swiper
              className="galeriaSwiper"
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {model.galeria.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div
            className={`textModel flex flexCol justifyCenter ${
              model.text !== "white" ? "textBlackcard" : ""
            }`}
          >
            <h2>Plan {model.nombre}</h2>
            <div className="cuota">
              Cuota<span>${model.cuota}</span>
            </div>
            <p className="resal">{model.plan}</p>
            <p className="resal" style={{ textDecoration: "underline" }}>{model.tenelo}</p>
            <p>{model.text1}</p>
            <p>{model.text2}</p>
            {model.text3 && <p>{model.text3}</p>}

            <button
              className="btn botonForm whitebtn"
              onClick={() => buttonData(true, model.dataButton, model.nombre)}
            >
              Consultar por este modelo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyModel;
